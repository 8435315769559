<script setup lang="ts">
  import MicroFrontendFrame from '@/components/MicroFrontendFrame.vue';
  import { computed, onMounted, onUnmounted, Ref, ref, watch } from 'vue';
  import { useMicroFrontendStore } from '@/stores/microFrontend';
  import { storeToRefs } from 'pinia';
  import { useRoute, useRouter } from 'vue-router';
  import { TARGET_ORIGIN } from '@/constants';
  import SideBar from '../components/SideBar.vue';
  import NavBar from '@/components/NavBar.vue';
  import { Edition, useUserDataStore } from '@upmetrics-platform/common-ui';
  import { OrgEditionName } from '@/utils/OrgEditionName.enum';
  import {
    impactFrameworkSpecification,
    legacyClientSpecification,
    specifications,
  } from '@/stores/microFrontend/specifications';
  import { logger } from '@/plugins/logger';

  const microFrontendStore = useMicroFrontendStore();
  const { edition }: { edition: Ref<Edition | null> } = storeToRefs(
    useUserDataStore()
  );
  const router = useRouter();
  const currentRoute = useRoute();

  let isNavigationTriggeredByChild = false;

  const { navigateTo, activeMicroFrontend } = storeToRefs(microFrontendStore);
  const hasHandshakeCompleted = computed(
    () => activeMicroFrontend.value?.hasHandshakeCompleted || false
  );

  const isOrganizationEditionEqImpactMeasurement = computed(
    () => edition.value?.name === OrgEditionName.IMPACT_MEASUREMENT
  ).value;

  window.addEventListener(
    'message',
    (event) => {
      if (event.origin === TARGET_ORIGIN)
        microFrontendStore.processReceivedMessage(event);
    },
    false
  );

  /**
   * Since that the navigation is triggered by a child, the best wey to keep the parent's
   * history clean is to replace the current path with the new one.
   */
  watch(navigateTo, async () => {
    const path = navigateTo.value;
    if (path && path !== currentRoute.fullPath) {
      isNavigationTriggeredByChild = true;
      await router
        .replace({ path })
        .catch((e) =>
          logger.error('[Navigation-UI] - watch - NavigateTo - ', e)
        );
    }
  });

  /**
   * This hook will be triggered by a child navigation (with the navigateTo watcher action)
   * or by the user navigating the app.
   * If the parent triggers the navigation, we always need to push history to the corresponding child.
   * If the navigation is triggered by a child, we only need to push history if we are switching from one micro frontend to another.
   */
  router.beforeEach((to, _from) => {
    const pathMatch = to.params.pathMatch;
    if (pathMatch) {
      const joinedPathMatch = (pathMatch as string[]).join('/');
      const path = `/${joinedPathMatch}`;
      if (isNavigationTriggeredByChild) {
        isNavigationTriggeredByChild = false;
      } else {
        microFrontendStore.pushHistory({ path });
      }
    }
  });

  /**
   * This trigger is useful when the user enters a non-base path, refreshes the app or is redirected to the identity service to sign in again.
   * In the first case, we want to 'redirect' the user to the desired location. For instance, /stories
   * In the second case, when the sign-in process completes, the url is a redirect to legacy --> /?rdr=/legacy/.
   * The best way to keep the parent's history clean is to replace the current URL with the "/" and let the router handler do its job.
   */
  watch(hasHandshakeCompleted, async (done) => {
    if (done) {
      const path = currentRoute.fullPath;
      if (path != '/') {
        if (path.startsWith('/?rdr=')) {
          await router
            .replace({ path: '/' })
            .catch((e) =>
              logger.error(
                '[Navigation-UI] - watch - hasHandShakeCompleted - ',
                e
              )
            );
        } else {
          microFrontendStore.pushHistory({ path });
        }
      }
    }
  });

  onUnmounted(() => {
    microFrontendStore.clearHandShake();
  });

  const activeSpecification = isOrganizationEditionEqImpactMeasurement
    ? impactFrameworkSpecification
    : legacyClientSpecification;

  const isSlim = ref(false);
  const iframeClassList = computed(() => {
    if (!isOrganizationEditionEqImpactMeasurement) return [];
    return [isSlim.value ? 'ml-14' : 'ml-[var(--sidebar-width)]'];
  });
</script>

<template>
  <template v-if="isOrganizationEditionEqImpactMeasurement">
    <side-bar @toggle="isSlim = $event" />
  </template>
  <template v-else>
    <nav-bar />
  </template>
  <div class="frame transition-all duration-100" :class="iframeClassList">
    <MicroFrontendFrame
      :key="activeSpecification.name"
      :base-path="activeSpecification.basePath"
      :name="activeSpecification.name" />
  </div>
</template>

<style scoped>
  .frame {
    flex: 1 1 auto;
  }
</style>
