<script setup lang="ts">
  import organization from '../../assets/organization.svg';
  import orgUsers from '../../assets/org-users.svg';
  import storyContributors from '../../assets/story-contributors.svg';
  import zap from '../../assets/zap.svg';
  import { storeToRefs } from 'pinia';
  import { useNavBarStore } from '@/stores/navBar';

  const store = useNavBarStore();
  const { orgAdminMenuItems } = storeToRefs(store);
  const emit = defineEmits(['routeTo']);

  const routeTo = (path: string) => {
    emit('routeTo', path);
  };

  const getSrc = (title: string) => {
    switch (title) {
      case 'My Organization':
        return organization;
      case 'Organization Users':
        return orgUsers;
      case 'Story Contributors':
        return storyContributors;
      default:
        return zap;
    }
  };
</script>

<template lang="pug">
div(v-for='item in orgAdminMenuItems', :key='item.title')
  button.flex.flex-row.items-center.w-full.px-4.py-2.text-left.text-sm.text-gray-50(
    type='button',
    class='gap-1.5 hover:bg-gray-600 hover:text-white',
    @click='routeTo(item.path)')
    img.w-4.h-4(:src='getSrc(item.title)', :alt='item.title')
    | {{ item.title }}
</template>
