import { defineStore } from 'pinia';
import type {
  AuthUser,
  Edition,
  Impersonation,
  Organization,
  PermissionSet,
} from '../../interfaces';

export interface UserDataState {
  user: AuthUser | null;
  org: Organization | null;
  edition: Edition | null;
  permissions: PermissionSet | null;
  impersonation: Impersonation;
  currentSession: any;
}

export const useUserDataStore = defineStore('userData', {
  state: (): UserDataState => ({
    user: null,
    org: null,
    edition: null,
    permissions: null,
    impersonation: { inProgress: false },
    currentSession: {},
  }),
  actions: {
    setAuthUser(user: AuthUser) {
      this.user = user;
    },
    setOrganization(org: Organization) {
      this.org = org;
    },
    setEdition(edition: Edition) {
      this.edition = edition;
    },
    setPermissions(permissions: Partial<PermissionSet>) {
      this.permissions = {
        ...this.permissions,
        ...permissions,
      } as PermissionSet;
    },
    setImpersonation(impersonation: Impersonation) {
      this.impersonation = impersonation;
    },
    setCurrentSession(session: any) {
      this.currentSession = session;
    },
    setUserNps(nps: any) {
      if (this.user) {
        this.user.nps = nps;
      }
    },
    stopImpersonation() {
      this.setImpersonation({ inProgress: false });
    },
  },
  persist: true,
});
