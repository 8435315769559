<script setup lang="ts">
  import { useRoute } from 'vue-router';

  const route = useRoute();
  const routes = [
    { path: '/data', name: 'Data' },
    { path: '/metrics', name: 'Metrics' },
    { path: '/goals', name: 'Goals' },
    { path: '/stories', name: 'Stories' },
  ];
</script>

<template lang="pug">
div#mobile-menu-2(class="items-center justify-between hidden w-full md:flex md:w-auto md:order-1")
  ul(class="flex flex-col p-3 mt-4 border rounded-lg md:flex-row md:space-x-8 md:hover:bg-transparent md:mt-0 md:text-sm md:font-medium md:border-0 bg-gray-800 md:bg-gray-900 border-gray-700")
    li(v-for="routeItem in routes" :key="routeItem.path")
      router-link(
        :to="routeItem.path"
        :class="route.fullPath.includes(routeItem.path) ? 'active' : ''"
        class="block py-2 pl-3 pr-4 text-gray-300 rounded hover:bg-gray-600 md:hover:bg-transparent md:hover:text-white md:p-0"
      ) {{ routeItem.name }}
</template>

<style scoped>
  #mobile-menu-2 {
    flex-grow: 8;
  }
  .active {
    color: #3f83f8;
  }
</style>
