<script setup lang="ts">
  import { ref, onMounted } from 'vue';
  import { MicroFrontendSpecification } from '@/stores/microFrontend/interface';
  import { useMicroFrontendStore } from '@/stores/microFrontend';

  const frameView = ref();
  const store = useMicroFrontendStore();

  const spec = defineProps<MicroFrontendSpecification>();

  onMounted(() => {
    store.addMicroFrontend(frameView.value, spec);
    /**
     * Right now we have a single microFrontend
     * We have a longer term need to support multiple frontends.
     * We will move the setActiveMicroFrontend Logic out of here as we work
     * those features
     */
    store.setActiveMicroFrontend(store.microFrontends[0]);
  });
</script>

<template>
  <iframe ref="frameView" :title="spec.name" :src="spec.basePath"></iframe>
</template>

<style scoped>
  iframe {
    width: 100%;
    height: 100%;
    display: block;
    border: none;
    overflow: hidden;
  }
</style>
