<script lang="ts" setup>
  import logo from '@/assets/up-metrics-logo.svg';
  import { computed, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import SideBarToggleButton from '@/components/SideBar/SideBarToggleButton.vue';
  import SideBarMenuItem from '@/components/SideBar/SideBarMenuItem.vue';
  import logoutIcon from '@/assets/logout.svg';
  import type { MenuItem } from '@/components/interfaces/menu-item';
  import { logger } from '@/plugins/logger';

  const emit = defineEmits(['toggle']);
  const router = useRouter();
  const header = ref<HTMLElement>();

  const headerHeight = computed(() => {
    return `${(header.value?.clientHeight || 1) * 1.1}px` ?? `0`;
  });

  const routeTo = async (path: string) => {
    await router
      .push(path)
      .catch((e) => logger.error('[Navigation-UI] - routeTo - ', e));
  };

  const isSlim = ref(false);
  const toggleSideBar = () => {
    isSlim.value = !isSlim.value;
    toggleCollapse('');
    emit('toggle', isSlim.value);
  };

  const toggleCollapse = (itemName: string) => {
    menuItems.value.forEach((item) => {
      if (item.name !== itemName) {
        item.isExpanded = isSlim.value ? false : item.isExpanded;
      } else {
        item.isExpanded = !item.isExpanded;
      }
    });
  };
  const classList = computed(() => {
    return {
      'w-14': isSlim.value,
      'w-[var(--sidebar-width)] px-4': !isSlim.value,
    };
  });

  const menuItems = ref<MenuItem[]>([
    {
      name: 'Home',
      icon: 'home',
      path: '/home',
      active: true,
    },
    {
      name: 'Define',
      icon: 'define',
      path: '/define',
      active: false,
      items: [
        {
          name: 'Framework',
          path: '/define/framework',
        },
      ],
      isExpanded: false,
    },
    {
      name: 'Collect',
      icon: 'collect',
      items: [
        {
          name: 'Portfolio data',
          path: '/collect/grantee-data',
        },
        {
          name: 'Operational data',
          path: '/collect/operational-data',
        },
        {
          name: 'Community data',
          path: '/collect/community-data',
        },
        {
          name: 'Stories',
          path: '/stories',
        },
      ],
      isExpanded: false,
      active: false,
    },
    {
      name: 'Analyze',
      icon: 'stats',
      items: [
        {
          name: 'Data',
          path: '/data',
        },
        {
          name: 'Dashboards',
          path: '/metrics',
        },
      ],
      isExpanded: false,
      active: false,
    },
    {
      name: 'Leverage',
      icon: 'pie-chart',
      items: [
        {
          name: 'Coming soon',
          path: '/leverage/sharing',
        },
      ],
      isExpanded: false,
      active: false,
    },
  ]);

  const footerMenu = [
    {
      name: 'Settings',
      icon: 'cog',
      path: '/settings',
      active: false,
    },
    {
      name: 'Account',
      icon: 'user-account-icon',
      path: '/account',
      active: false,
    },
  ];
</script>
<template lang="pug">
aside.fixed.inset-y-0.left-0.z-20.bg-gray-900.transition-all.duration-100.h-screen(
  :class='classList')
  .flex.flex-row.items-center.h-16(
    ref='header',
    :class='[isSlim ? "px-4 justify-center" : "justify-between pl-4 pr-3"]')
    router-link(v-if='!isSlim', to='/')
      img(:src='logo', alt='UpMetrics')
    SideBarToggleButton(@click='toggleSideBar')
  .sidebar-height.flex.flex-col.justify-between
    div
      SideBarMenuItem(
        v-for='item of menuItems',
        :key='item.name',
        :icon='item.icon',
        :label='item.name',
        :path='item.path',
        :active='item.active',
        :has-sub-menu='Boolean(item.items)',
        :expanded='item.isExpanded',
        :is-slim='isSlim',
        @toggle='toggleCollapse',
        @navigate='routeTo')
        template(v-if='item.items')
          SideBarMenuItem(
            v-for='subItem of item.items || []',
            :key='subItem.name',
            :icon='subItem.icon',
            :label='subItem.name',
            :path='subItem.path',
            :active='subItem.active',
            :is-slim='isSlim',
            is-sub-menu,
            @navigate='routeTo')
    .border-t.border-white.border-opacity-20.relative.pt-3
      SideBarMenuItem(
        v-for='item of footerMenu',
        :key='item.name',
        :is-slim='isSlim',
        :icon='item.icon',
        :label='item.name',
        :active='item.active',
        @toggle='toggleCollapse')
      .flex.py-2.cursor-pointer.items-center.px-2(
        :class='[isSlim ? "justify-center" : "absolute bottom-2 right-0 hover:bg-white hover:bg-opacity-20 hover:rounded"]')
        button
          img(:src='logoutIcon', alt='UpMetrics-logout', class='w-[16px] h-[16px]')
</template>
<style>
  .sidebar-height {
    height: calc(100vh - v-bind(headerHeight));
  }
</style>
