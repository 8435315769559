<script setup lang="ts">
  import Logo from './NavBar/Logo.vue';
  import UserMenu from './NavBar/UserMenu.vue';
  import Links from './NavBar/Links.vue';
  import { useNavBarStore } from '@/stores/navBar';
  import { storeToRefs } from 'pinia';

  const store = useNavBarStore();
  const { readyToNavigate } = storeToRefs(store);
</script>

<template lang="pug">
nav.bg-gray-900(v-if='readyToNavigate', class='min-h-[64px] pt-[2px] md:pt-0')
  .container.flex.flex-wrap.items-center.justify-between.mx-auto.px-4(class='py-2.5')
    Logo
    UserMenu
    Links
</template>
