import { createApp } from 'vue';
import 'flowbite';
import './style.css';
import App from './App.vue';
import router from './router';
import { useLoggerIn } from './plugins/logger';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
const app = createApp(App);

useLoggerIn(app);

const dsn = import.meta.env.VITE_SENTRY_DSN;
const environment = import.meta.env.VITE_CDK_ENV;
const isProd = import.meta.env.PROD;
const tracesSampleRate = parseFloat(import.meta.env.VITE_SAMPLE_RATE || '0.2');

if (dsn && isProd) {
  Sentry.init({
    app,
    dsn,
    environment,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: [
          'https://staging.upmetrics.com',
          'https://app.upmetrics.com',
        ],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate,
  });
}

app.use(pinia).use(router).mount('#app');
