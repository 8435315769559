<script setup lang="ts">
  import { useMicroFrontendStore } from '@/stores/microFrontend';
  import { storeToRefs } from 'pinia';
  import { onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import type { DropdownOptions } from 'flowbite';
  import { Dropdown, initCollapses } from 'flowbite';
  import OrgAdminMenuItems from './OrgAdminMenuItems.vue';
  import SuperAdminMenuItems from './SuperAdminMenuItems.vue';
  import { useUserDataStore } from '@upmetrics-platform/common-ui';
  import { logger } from '@/plugins/logger';

  const microFrontendStore = useMicroFrontendStore();

  const userDataStore = useUserDataStore();
  const router = useRouter();
  const { activeMicroFrontend } = storeToRefs(microFrontendStore);
  const { user, org, impersonation } = storeToRefs(userDataStore);

  const routeTo = async (path: string) => {
    await router
      .push(path)
      .catch((e) => logger.error('[Navigation-UI] - routeTo - ', e));
    microFrontendStore.hideDropdown();
  };

  const userMenuButton = ref<HTMLElement>();
  const userDropdown = ref<HTMLElement>();
  const options: DropdownOptions = {
    placement: 'left-end',
    triggerType: 'click',
    offsetSkidding: 36,
    offsetDistance: -48,
    onHide: () => {
      activeMicroFrontend.value?.contentWindow?.document.removeEventListener(
        'click',
        microFrontendStore.hideDropdown
      );
    },
    onShow: () => {
      activeMicroFrontend.value?.contentWindow?.document.addEventListener(
        'click',
        microFrontendStore.hideDropdown
      );
    },
  };

  onMounted(() => {
    initCollapses();
    microFrontendStore.setDropdown(
      new Dropdown(userDropdown.value, userMenuButton.value, options)
    );
  });

  function navigateToStopImpersonation() {
    window.location.href = '/identity/stop-impersonation';
  }
</script>

<template lang="pug">
.flex.items-center(class='md:order-2')
  button.w-7.h-7.flex.bg-gray-800.rounded-full(
    ref='userMenuButton',
    data-testid="navbar-user-menu-button",
    type='button',
    class='focus:ring-2 focus:ring-gray-600',
    aria-expanded='false')
    span.sr-only Open user menu
    img(src='../../assets/user-icon.svg', alt='User icon')
  // Dropdown menu
  .z-50.hidden.my-4.text-base.list-none.rounded-lg.shadow.bg-gray-700(
    ref='userDropdown')
    .px-4.py-3
      span.block.text-md.font-medium.text-white(data-testid="navbar-user-name") {{ user?.name }}
      span.block.text-xs.font-normal.truncate.text-white(data-testid="navbar-user-email") {{ user?.email }}
      span.block.text-xs.font-bold.truncate.text-white(data-testid="navbar-org-name") {{ org?.name }}
    div(aria-labelledby='user-menu-button')
      button.border-b.border-gray-600.flex.flex-row.items-center.w-full.px-4.py-2.text-left.text-sm.text-gray-50(
        type='button',
        class='gap-1.5 hover:bg-gray-600 hover:text-white',
        @click='routeTo("/my_account")')
        img.w-4.h-4(
          src='../../assets/user-account-icon.svg',
          alt='User account icon')
        | My Account
    .flex.flex-row.items-center.w-full.px-4.py-2.text-left.text-sm.bg-gray-600.text-gray-50(
      v-if='impersonation.inProgress',
      class='gap-1.5'
      data-testid="navbar-impersonation-in-progress")
      img.w-4.h-4(
        src='../../assets/impersonation-in-progress.svg',
        alt='Impersonation in progress')
      | Impersonation in progress
    div(v-if='impersonation.inProgress')
      button.border-b.border-gray-600.flex.flex-row.items-center.w-full.px-4.py-2.text-left.text-sm.text-gray-50(
        type='button',
        data-testid="navbar-stop-impersonating",
        class='gap-1.5 hover:bg-gray-600 hover:text-white',
        @click='navigateToStopImpersonation()')
        img.w-4.h-4(
          src='../../assets/impersonation-stop.svg',
          alt='Stop Impersonating')
        | Stop Impersonating
    OrgAdminMenuItems(@route-to='routeTo')
    SuperAdminMenuItems(@route-to='routeTo')
    div(aria-labelledby='user-menu-button')
      button#start-sign-out-button.border-t.border-gray-600.w-full.px-4.py-2.text-left.text-sm.text-white(
        type='button',
        class='hover:bg-gray-600 hover:text-white hover:rounded-b-lg',
        @click='routeTo("/identity/sign-out")') Sign Out
  // Mobile navigation menu button
  button.inline-flex.items-center.p-2.ml-1.text-sm.rounded-lg.text-gray-400(
    data-collapse-toggle='mobile-menu-2',
    type='button',
    class='md:hidden focus:outline-none focus:ring-2 hover:bg-gray-700 focus:ring-gray-600',
    aria-controls='mobile-menu-2',
    aria-expanded='false')
    span.sr-only Open main menu
    svg.w-6.h-6(
      aria-hidden='true',
      fill='currentColor',
      viewBox='0 0 20 20',
      xmlns='http://www.w3.org/2000/svg')
      path(
        fill-rule='evenodd',
        d='M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z',
        clip-rule='evenodd')
</template>
