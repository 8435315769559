import { defineStore } from 'pinia';

import {
  SetMenuItemsData,
  UserDataState as State,
  UserMenuItem,
} from './interface';

export const useNavBarStore = defineStore('navBar', {
  state: (): State => ({
    orgAdminMenuItems: [],
    superAdminMenuItems: [],
    readyToNavigate: false,
  }),
  actions: {
    setMenuItems({ orgAdminMenuItems, superAdminMenuItems }: SetMenuItemsData) {
      this.setOrgAdminMenuItems(orgAdminMenuItems);
      this.setSuperAdminMenuItems(superAdminMenuItems);
    },
    setOrgAdminMenuItems(orgAdminMenuItems: UserMenuItem[]) {
      this.orgAdminMenuItems = orgAdminMenuItems;
    },
    setSuperAdminMenuItems(superAdminMenuItems: UserMenuItem[]) {
      this.superAdminMenuItems = superAdminMenuItems;
    },
    setReadyToNavigate(readyToNavigate: boolean) {
      this.readyToNavigate = readyToNavigate;
    },
    logOutUser() {
      this.$reset();
    },
  },
});
