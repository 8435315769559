import { MicroFrontendSpecification } from './interface';

export const legacyClientSpecification: MicroFrontendSpecification = {
  name: 'legacy',
  basePath: '/legacy/',
};

export const impactFrameworkSpecification: MicroFrontendSpecification = {
  name: 'impact-framework',
  basePath: '/impact-framework/',
};

export const specifications = [
  legacyClientSpecification,
  impactFrameworkSpecification,
];
