<script lang="ts" setup>
  import { toRefs } from 'vue';

  const props = defineProps({
    icon: { type: String, required: false, default: null },
    label: { type: String, required: true },
    path: { type: String, required: false, default: null },
    active: Boolean,
    isSubMenu: {
      type: Boolean,
      default: false,
      required: false,
    },
    isSlim: {
      type: Boolean,
      required: true,
      default: false,
    },
    hasSubMenu: {
      type: Boolean,
      required: false,
      default: false,
    },
    expanded: {
      type: Boolean,
      required: false,
      default: false,
    },
  });

  const emit = defineEmits(['navigate', 'toggle']);

  const handleClick = () => {
    if (props.hasSubMenu) {
      emit('toggle', props.label);
    }
    if (props.path) {
      emit('navigate', props.path);
    }
  };

  const { icon, label, active, isSubMenu, isSlim } = toRefs(props);

  const getIcon = () => {
    return new URL(`../../assets/${icon.value}.svg`, import.meta.url).href;
  };
</script>

<template lang="pug">
.relative
  .flex.items-center.cursor-pointer.my-1(
    class='hover:bg-white hover:bg-opacity-20 hover:rounded',
    :class='[{ "ml-7": isSubMenu && !isSlim }, { "bg-white rounded bg-opacity-20 font-semibold": active }, [isSlim && !isSubMenu ? "justify-center mx-1" : "justify-between pr-2"]]',
    @click='handleClick')
    .flex.items-center.p-4(:class='[isSlim ? "py-3" : "py-2"]')
      img.w-4.h-4(
        v-if='icon',
        :src='getIcon()',
        :alt='label',
        :class='{ "mr-2": !isSlim }')
      span.text-gray-100.flex.transition-all.delay-300.duration-300.ease-in-out(
        :class='{ hidden: isSlim && !isSubMenu }')
        | {{ label }}
    div(v-if='hasSubMenu && !isSlim')
      svg.w-5.h-5.text-white(
        :class='{ "transform rotate-180": expanded }',
        fill='none',
        stroke='currentColor',
        viewBox='0 0 24 24',
        xmlns='http://www.w3.org/2000/svg')
        path(
          stroke-linecap='round',
          stroke-linejoin='round',
          stroke-width='2',
          d='M19 9l-7 7-7-7')
  .bg-gray-900.delay-3.transition-height.duration-300.ease-in-out.overflow-hidden(
    :class='[{ "absolute left-14 top-0 w-44": isSlim }, { hidden: !expanded }]')
    slot
</template>
