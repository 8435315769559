<script setup lang="ts">
  import { storeToRefs } from 'pinia';
  import { useNavBarStore } from '@/stores/navBar';

  const store = useNavBarStore();
  const { superAdminMenuItems } = storeToRefs(store);
  const emit = defineEmits(['routeTo']);

  function routeTo(path: string) {
    emit('routeTo', path);
  }
</script>

<template lang="pug">
div(v-if='superAdminMenuItems.length')
  .flex.flex-row.items-center.w-full.px-4.py-2.text-left.text-sm.bg-gray-600.text-gray-50(
    class='gap-1.5')
    img.w-4.h-4(src='../../assets/zap.svg', alt='Super Admin Menu Items')
    | Super Admin
div(v-for='item in superAdminMenuItems', :key='item.title')
  button.flex.flex-row.items-center.w-full.px-4.py-2.text-left.text-sm.text-gray-50(
    type='button',
    class='gap-1.5 hover:bg-gray-600 hover:text-white',
    @click='routeTo(item.path)') {{ item.title }}
</template>
