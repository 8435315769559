import {
  createWebHistory,
  createRouter,
  RouteRecordRaw,
  RouteLocationNormalized,
  NavigationGuardNext,
} from 'vue-router';
import NavigationWrapper from '../views/NavigationWrapper.vue';
import Error404 from '@/components/Error404.vue';
import { BASE_URL } from '@/constants';

const routes: RouteRecordRaw[] = [
  // identity UI is a special case that requires changing location entirely
  // instead of using the navigation wrapper
  {
    path: '/identity/:pathMatch(.*)*',
    name: 'IdentityUI',
    component: {},
    beforeEnter(
      to: RouteLocationNormalized,
      _from: RouteLocationNormalized,
      _next: NavigationGuardNext
    ) {
      window.location.href = to.fullPath;
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NavigationWrapper',
    component: NavigationWrapper,
  },
  {
    path: '/404',
    name: '404',
    component: Error404,
  },
];

const router = createRouter({
  history: createWebHistory(BASE_URL),
  routes,
});

export default router;
