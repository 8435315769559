import { App } from 'vue';
import VueLogger, { Log } from 'vuejs3-logger';
import { LogLevels } from 'vuejs3-logger/dist/enum/log-levels';
import { ILoggerOptions } from 'vuejs3-logger/dist/interfaces/logger-options';

export let logger: Log;

const isProduction = import.meta.env.PROD;

const options: ILoggerOptions = {
  isEnabled: true,
  logLevel: isProduction ? LogLevels.ERROR : LogLevels.DEBUG,
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true,
};

export const useLoggerIn = (app: App<unknown>) => {
  app.use(VueLogger, options);
  logger = app.config.globalProperties.$log;
};
